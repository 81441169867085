import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaBreadSlice, FaCertificate } from "react-icons/fa";
import { IoMdMan } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Пекарня Хлібне Діло
			</title>
			<meta name={"description"} content={"Ласкаво просимо до пекарні \"Хлібне Діло\", де традиції поєднуються з інноваціями в кожній буханці."} />
			<meta property={"og:title"} content={"Головна | Пекарня Хлібне Діло"} />
			<meta property={"og:description"} content={"Ласкаво просимо до пекарні \"Хлібне Діло\", де традиції поєднуються з інноваціями в кожній буханці."} />
			<meta property={"og:image"} content={"https://ustined-liss.com/media/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ustined-liss.com/media/1669110.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ustined-liss.com/media/1669110.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ustined-liss.com/media/1669110.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ustined-liss.com/media/1669110.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ustined-liss.com/media/1669110.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ustined-liss.com/media/1669110.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="50px 0 50px 0" sm-padding="80px 0 50px 0" quarkly-title="Hero-11" sm-text-align="center">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				lg-align-items="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					padding="80px 0px 80px 0px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					sm-padding="0 0px 50px 0px"
				>
					<Text margin="0px 0px 30px 0px" font="normal 700 42px/1.2 --fontFamily-sans" sm-font="normal 700 36px/1.2 --fontFamily-sans" color="--darkL2">
						Хлібне Діло
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
						Ласкаво просимо до пекарні "Хлібне Діло", де традиції поєднуються з інноваціями в кожній буханці. Наша пекарня - це теплий притулок для тих, хто цінує просту насолоду від свіжоспеченого хліба. Ми поєднуємо перевірені часом технології з творчим підходом, щоб запропонувати вам широкий асортимент хліба, тістечок та спеціальної випічки, які є настільки ж корисними, як і смачними.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-width="100%"
						flex-direction="column"
					>
						<Button
							padding="15px 38px 15px 38px"
							background="--color-primary"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							color="--dark"
							letter-spacing="1px"
							margin="0px 0px 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="--primary"
							hover-color="--darkL1"
							hover-background="rgba(215, 26, 89, 0.03)"
							href="/contact-us"
							type="link"
							text-decoration-line="initial"
						>
							Знайти Нас
						</Button>
					</Box>
				</Box>
				<Image
					src="https://ustined-liss.com/media/1.jpg"
					display="block"
					width="40%"
					object-fit="cover"
					sm-height="500px"
					height="100%"
					lg-width="100%"
					lg-height="auto"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Чому варто обрати пекарню "Хлібне Діло"?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					text-align="center"
					max-width="480px"
				>
					Кожен продукт виготовляється вручну з використанням лише найкращих натуральних інгредієнтів, що гарантує автентичний та незабутній смак.
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#eae7df"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaBreadSlice}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Різноманітність та інновації
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Від класичного хліба до авангардної випічки - наш асортимент постійно розвивається, пропонуючи щось на будь-який смак.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#e9e6e0"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="io"
						icon={IoMdMan}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Орієнтованість на покупців
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Ми більше, ніж просто пекарня - ми центр громади, де люди об'єднуються через любов до хорошого хліба.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#e9e6e0"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaCertificate}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Індивідуальні замовлення та події
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Незалежно від того, чи це особлива подія, чи корпоративний захід, ми пропонуємо персоналізовані послуги, щоб задовольнити ваші унікальні потреби.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://ustined-liss.com/media/2.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://ustined-liss.com/media/3.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Відчуйте різницю - пекарня "Хлібне Діло", що створює спогади один буханець за раз
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});